import React from "react"
import { Link } from "gatsby"
import './Footer.scss'

import LogoType from "!svg-react-loader!../../../../content/assets/logotype.svg?name=LogoType";

class Footer extends React.Component {
  render() {
    return (
      <footer className="section">
        <div className="container px-4 px-sm-0">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="blurb col-md-5 col-sm-12 col-xs-12">
                  <div className="brand">
                    <LogoType className="logo" />
                  </div>
                  <p>As full-time freelancers, we've seen it all. Financial ups and downs. Clients who aren't a good fit. Burnout from long hours, and no vacations. <strong>Hammock is the tool we wish we had when we started freelancing.</strong></p>
                  {/* with <MountainIcon className="mountain-love" />  */}
                  <p>Made in Longmont, CO  |  &copy; 2019, Canarykit, LLC.</p>
                </div>

                <div className="col-lg-2 offset-lg-1 col-md-2 offset-md-1 col-sm-4 col-xs-4">
                  <div className="footer-block">
                    <h6 className="title">Company</h6>
                    <ul>
                      <li>
                        <Link to="/about" rel="canonical">About</Link>
                      </li>
                      <li>
                        <Link to="/blog" rel="canonical">Blog</Link>
                      </li>
                      <li>
                        <a id="chat" href="#chat" onClick={() => {window.HubSpotConversations.widget.open()}}>Chat</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4">
                  <div className="footer-block">
                    <h6 className="title">Free Freelancer Tools</h6>
                    <ul>
                      <li>
                        <Link to="/freelance-starter-guide" rel="canonical">
                          Freelance Starter Guide
                        </Link>
                      </li>

                      <li>
                        <Link to="/runway-calculator" rel="canonical">
                          Runway Calculator
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog/2019/07/28/howto-achieve-financial-stability-as-a-fulltime-freelancer/" rel="canonical">
                          Salary Calculator
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4">
                  <div className="footer-block">
                    <h6 className="title">Legal</h6>
                    <ul>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms and Conditions</Link>
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer

