import React from "react"
import { Link } from "gatsby"
import './Header.scss'

import LogoType from "!svg-react-loader!../../../../content/assets/logotype.svg?name=LogoType";
import HamburgerMenu from "!svg-react-loader!../../../../content/assets/HamburgerMenu.svg?name=HamburgerMenu";
import Dropdown from 'react-bootstrap/Dropdown'
import DropDownItem from './DropDownItem.jsx'

import DashboardIcon from '../../../../content/assets/app/svg/icons/analytics-bars.svg'
import QuarterlyTaxesIcon from '../../../../content/assets/app/svg/icons/calendar-cash-1.svg'
import TransactionsIcon from '../../../../content/assets/app/svg/icons/task-list-multiple.svg'


 

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.navbar = React.createRef();
  }

  handleCollapseClick = (e) => {
    e.preventDefault()
    this.navbar.current.classList.toggle('show');
  }

  render() {
    return (
      <div className="app-navbar mb-4 mt-4">
        <div className="container px-4 px-sm-0">

          <nav className="navbar navbar-expand-md navbar-light">      
            <a href='https://tryhammock.com/' className="brand">
              <LogoType className="logo" />
            </a>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" onClick={this.handleCollapseClick}>
              <HamburgerMenu />
            </button>

            <div className="collapse navbar-collapse" id="collapsibleNavbar" ref={this.navbar}>
              <ul className="navbar-nav offset-xl-2 offset-lg-1">
                <li className="nav-item">
                  <Dropdown className="ml-auto">
                    <Dropdown.Toggle as="a" href="#features" className="nav-link">
                      Features
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                      <DropDownItem href="/features/runway" rel="canonical">
                      <DashboardIcon className="nav-link-icon" />
                        Runway Insights
                      </DropDownItem>
                      <DropDownItem href="/features/expense-tracking" rel="canonical">
                        <TransactionsIcon className="nav-link-icon" />
                        Expense Tracking
                      </DropDownItem>
                      <DropDownItem href="/features/estimated-taxes" rel="canonical">
                        <QuarterlyTaxesIcon className="nav-link-icon" />
                        Estimated Taxes
                      </DropDownItem>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" rel="canonical"  activeClassName="active" to="/pricing">
                    Pricing
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" rel="canonical" to="/blog" activeClassName="active">
                    Blog
                  </Link>
                </li>

                <li className="nav-item d-md-none">
                  <a className="btn btn-outline-primary login-btn ml-auto" rel="canonical" href="https://app.tryhammock.com/login">
                    Login
                  </a>
                </li>

                <li className="nav-item d-md-none">
                  <a className="btn btn-primary signup-btn" rel="canonical" href="https://app.tryhammock.com/signup">
                    Signup for <strong>FREE</strong>
                  </a>
                </li>

              </ul>

              <a className="btn btn-outline-primary login-btn ml-auto d-none d-md-block" rel="canonical" href="https://app.tryhammock.com/login">
                Login
              </a>

              <a className="btn btn-primary signup-btn d-none d-md-block" rel="canonical" href="https://app.tryhammock.com/signup">
                Signup for <strong>FREE</strong>
              </a>



            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default Header

